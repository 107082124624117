import { Record } from 'immutable'
import {
    DASHBOARD_DATES_LOADED,
    DASHBOARD_SET_BEGIN_DATE,
    DASHBOARD_SET_END_DATE,
    DASHBOARD_SET_SELECTED_SCALE_ID,
    METRIC_VALUES_LOAD,
    REQUEST,
    SUCCESS,
    FAIL
} from '~/constants'
import moment from '~/moment'

const ReducerRecord = Record({
    beginDate: moment().add(-7, 'days'),
    endDate: moment(),
    selectedScaleId: 'all',
    gains: [],
    metricValues: [],
    loading: false,
    error: false
})

const defaultState = new ReducerRecord()

export default function dashboardReducer(state = defaultState, action) {
    const { type, payload } = action

    switch (type) {
        case DASHBOARD_DATES_LOADED: {
            const { beginDate, endDate } = payload
            return state
                .set('beginDate', beginDate)
                .set('endDate', endDate)
        }

        case DASHBOARD_SET_BEGIN_DATE: {
            const { beginDate } = payload
            return state.set('beginDate', beginDate)
        }

        case DASHBOARD_SET_END_DATE: {
            const { endDate } = payload
            return state.set('endDate', endDate)
        }

        case DASHBOARD_SET_SELECTED_SCALE_ID: {
            const { selectedScaleId } = payload
            return state.set('selectedScaleId', selectedScaleId)
        }

        case REQUEST(METRIC_VALUES_LOAD):
            return state
                .set('loading', true)
                .set('error', false)

        case SUCCESS(METRIC_VALUES_LOAD): {
            const { gains, values: metricValues } = payload
            return state
                .set('gains', gains)
                .set('metricValues', metricValues)
                .set('loading', false)
        }

        case FAIL(METRIC_VALUES_LOAD):
            return state
                .set('loading', false)
                .set('error', true)

        default:
            return state
    }
}
